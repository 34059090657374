<template>
  <div>
    <parkfulheader></parkfulheader>
      <head>
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </head>
    
    <router-view/>
    <parkfulfooter></parkfulfooter>
  </div>
</template>

<script>
import parkfulheader from './components/parkful-header'
import parkfulfooter from './components/parkful-footer'

export default {
  components: {
    parkfulheader,
    parkfulfooter
  },
}
</script>