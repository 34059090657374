<template>
  <div>
    
    <div class="uk-child-width-1-1@s uk-child-width-1-3@m uk-grid-large app-body" uk-grid>
      <div class="pf-post" v-for="item in timeline" :key='item.id'>
        
        <div class="uk-card uk-card-default uk-card-body card-body">
          
          <img :data-src=item.image.url width="" height="" alt="" class="card-image" uk-img>
          <div class="card-inside">
            <div class="card-title">
              <span v-if="item['park']['prefecture'] && item['park']['city']">
                <h2 class="uk-card-title"><router-link :to="{name:'park-detail',params:{prefecture:item.park.prefecture.name, city:item.park.city.name, parkname:item.park.name, id:item.park.id }}" target="" rel="noopener noreferrer">{{ item.park.name }}</router-link><a href="https://parkful.net/2020/04/parkful-app/" target="_blank" rel="noopener noreferrer"><img src="../assets/logo_parkful.png" alt="info" class="info-icon"></a></h2>
              </span>
              <span v-else>
                <h2 class="uk-card-title">{{ item.park.name }}<a href="https://parkful.net/2020/04/parkful-app/" target="_blank" rel="noopener noreferrer"><img src="../assets/logo_parkful.png" alt="info" class="info-icon"></a></h2>
              </span>

            </div>
            <div class="card-park-name">
              <img src="../assets/logo_pin.png" alt="pin" class="pin-icon">{{ item.park.prefecture.name }} <span v-if="item['park']['city']">{{ item.park.city.name }}</span><br>
            </div>
            <div class="card-user">
              <div class="card-like">
                <img src="../assets/logo_heart.png" alt="heart" class="heart-icon"><span class="like_cnt">{{ item.like_cnt }}</span><br>
              </div>
              <div v-if=item.user.profile_image_url>
                <img :data-src=item.user.profile_image_url width="" height="" alt="" class="user-icon" uk-img><span class="user-name">{{ item.user.name }}</span><br>
              </div>
              <div v-else>
                <img src="../assets/logo_user.png" alt="user" class="user-icon"><span class="user-name">{{ item.user.name }}</span><br>
              </div>
              {{ dateFomat(item.updated_at) }}
            </div>
            <div class="card-content">
              {{ item.content }}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  components: {
    
  },
  methods: {
    dateFomat(d) {
        return moment(d).format('YYYY/MM/DD HH:mm');
    },

    async getToken(){
      const formData = new FormData()
      formData.set('tokenkey','975663ca9733ced421b9d249befab1b3')
      await fetch('https://api.parkful.com/api/v4/api_token', {
        method: 'POST',
        body: formData,
        headers: {
          CLIENTID: 'parkful',
          'Accept': 'application/json'
          
        },
      }).then(resp => {
        return resp.json()
      }).then(resp => {
        this.tokendata = resp.token;  // トークンをセット
      });
    },

    getTimeline(){
      fetch('https://api.parkful.com/api/v4/posts/select_authentication?per_page=50', {
        method: 'GET',
        headers: {
          TOKEN: this.tokendata,
          CLIENTID: 'parkful',
          'Authorization': 'Bearer', 
          'Content-Type': 'application/json',
        },
      }).then(resp => {
        return resp.json()
      }).then(resp => {
        this.timeline = resp.filter(item => item['image']);  // timeline配列にロードしたデータをセット
      });
    },
  },
  
  data: function() {
    return { // この画面で管理したいデータ構造を定義
      timeline:[],
      tokendata:[]
    }     
  },

  async created () {
    await this.getToken(); // createdのタイミングでデータロード
    this.getTimeline();
  },

  metaInfo: {
    title: "PARKFUL | 公園をもっと楽しく、もっと身近に。全国の公園情報からお気に入りを探そう！",
    meta: [
      { name: 'description', content: "PARKFUL(パークフル）は、日本全国の公園情報やニュース、イベント、流行をお伝えする専門メディアです。公園の今を多くの方に知っていただくことで、公園を活用した子育て環境、地域コミュニティや地方経済の活性化について考えていただくきっかけを作っていきます。" },
      { name: 'keywords', content: "公園,park,遊び,公園アプリ,公園情報,公園一覧,子育て,大きな,無料,アプリ,花見,紅葉,パーク,滑り台,ジャングルジム,コトブキ,イベント,お出かけ,おでかけ,こども,子供,フォトコン,写真,桜,カフェ,点検,日常点検,定期点検,安全,安心" }
    ],
  },
  
}
</script>

<style>

@media screen and (min-width: 960px) {
  /* 960px以上に適用されるCSS（PC用） */

  .pf-post {
    padding: 0px 0px 0px 50px !important;
  }

  .card-body {
    padding: 0px 0px 0px 0px !important;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35) !important;
    border-radius: 16px !important;  
  }

  .card-inside { 
    margin: 20px 20px !important;
  }
  .card-image {
    border-radius: 16px 16px 0 0
  }

  .card-title a {
    color: black;
  }
  .info-icon {
    margin-top: 6px;
    float: right;
    width: 20px;
    height: 20px;
  }

  .pin-icon {
    margin-right: 5px;
    width: 10px;
    height: 15px;
    margin-bottom: 2px;

  }
  .card-user {
    margin-top: 1em;
    font-size: 13px;
    
  }

  .user-icon {
    border-radius: 50%;
    float: left;
    margin-top: 3px;
    margin-right: 10px !important;
    width: 40px;
    height: 40px;
  }

  .user-name {
    font-size: 16px;
  }

  .card-like {
    float: right;
    padding-top: 8px;
    font-size: 18px;
  }

  .heart-icon {
    margin-right: 7px !important;
    margin-bottom: 2px !important;
    width: 15px;
    height: 15px;
  }

  .like_cnt {
    margin-top: 2px !important;
  }

  .card-content {
    margin-top: 10px;
    font-size: 20px;
  }

  .app-body {
    margin-left: 8em !important;
    margin-right: 8em !important;
  }

}

@media screen and (max-width: 959px) {
	/* 959px以下に適用されるCSS（タブレット用） */
  .pf-post {
    padding: 0px 0px 0px 50px !important;
    margin-right: 10px !important;
  }
  .card-body {
    padding: 0px 0px 0px 0px !important;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35) !important;
    border-radius: 16px !important;
  }

  .card-inside {
    margin: 20px 20px !important;
  }
  .card-image {
    border-radius: 16px 16px 0 0
  }
  .card-title a {
    color: black;
  }
  .info-icon {
    margin-top: 6px;
    float: right;
    width: 20px;
    height: 20px;
  }

  .pin-icon {
    margin-right: 5px;
    width: 10px;
    height: 15px;
    margin-bottom: 2px;

  }
  .card-user {
    margin-top: 1em;
    font-size: 13px;
  }

  .user-icon {
    border-radius: 50%;
    float: left;
    margin-top: 3px;
    margin-right: 10px !important;
    width: 40px;
    height: 40px;
  }

  .user-name {
    font-size: 16px;
  }

  .card-like {
    float: right;
    padding-top: 8px;
    font-size: 18px;
  }

  .heart-icon {
    margin-right: 7px !important;
    margin-bottom: 2px !important;
    width: 15px;
    height: 15px;
  }

  .like_cnt {
    margin-top: 2px !important;
  }

  .card-content {
    margin-top: 10px;
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
	/* 480px以下に適用されるCSS（スマホ用） */
  .pf-post {
    padding: 0px 0px 0px 50px !important;
    margin-right: 10px !important;
  }
  .card-body {
    padding: 0px 0px 0px 0px !important;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35) !important;
    border-radius: 16px !important;  
  }

  .card-inside { 
    margin: 20px 20px !important;
  }
  .card-image {
    border-radius: 16px 16px 0 0
  }

  .card-title a {
    color: black;
  }

  .info-icon {
    margin-top: 6px;
    float: right;
    width: 20px;
    height: 20px;
  }


  .pin-icon {
    margin-right: 5px;
    width: 10px;
    height: 15px;
    margin-bottom: 2px;

  }
  .card-user {
    margin-top: 1em;
    font-size: 13px;
  }

  .user-icon {
    border-radius: 50%;
    float: left;
    margin-top: 3px;
    margin-right: 10px !important;
    width: 40px;
    height: 40px;
  }

  .user-name {
    font-size: 16px;
  }

  .card-like {
    float: right;
    padding-top: 8px;
    font-size: 18px;
  }

  .heart-icon {
    margin-right: 7px !important;
    margin-bottom: 2px !important;
    width: 15px;
    height: 15px;
  }

  .like_cnt {
    margin-top: 2px !important;
  }

  .card-content {
    margin-top: 10px;
    font-size: 20px;
  }


}


</style>