import Vue from 'vue'
import Router from 'vue-router'
import Timeline from './components/TimeLine.vue'
import ParkDetail from './components/ParkDetail.vue'
import VueGtag from 'vue-gtag'

Vue.use(Router)

export default new Router({
  mode: 'history',                  
  routes: [
    {
      path: '/',
      name: 'timeline',
      component: Timeline
    },
    {
      path: '/:prefecture/:city/:parkname/:id',
      name: 'park-detail',
      component: ParkDetail 
    } 
  ]
})

Vue.use(VueGtag, {
  config: { id: "G-2JMZR1DX6B" }
});
  