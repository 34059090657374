<template>
  <div class="star">
    <div class="park-stars" v-for="star in starDisplay()" :key='star.id'>
      <div v-if="star == 1">
        <img src="../assets/star_1.png" alt="star-1" class="star-1-icon">
      </div>
      <div v-if="star == 0.5">
        <img src="../assets/star_0.5.png" alt="star-0.5" class="star-0.5-icon">
      </div>
      <div v-if="star == 0">
        <img src="../assets/star_0.png" alt="star-0" class="star-0-icon">
      </div>
    </div> 
  </div>
</template>

<script>

export default {
  name: 'StarReview',
  props:[
      'data'
  ],


  methods: {

    starDisplay() {
      const starArray = []
      for(let i=0; i < 5; i++){
        const starVal = this.data - i
        if(this.data == 0 || starVal <= 0 ) {
          starArray.push('0')

        }else if (starVal >= 1){
          starArray.push('1')

        }else if (starVal > 0 && starVal < 1){
          starArray.push('0.5')

        }

      }
      return starArray;
    }

  },
  created () {
  },

}


</script>

<style>

  .park-stars{
    padding-left: 1px !important;
    margin-bottom: 1em;
    width: 20px;
    height: 20px;
    float: left;

  }
  
</style>





