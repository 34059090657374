<template>
  <div>
    <head>
      <title></title>
      <meta name="description" content="" />
      <meta name="keywords" content="" />
    </head>
    <div class="detail-body">
      <div v-if="parkDateil">
        <div v-if="parkDateil.images.length">
          <span v-if="parkDateil.images"><img :data-src=parkDateil.images[0].url width="" height="" alt="" class="dateil-image" uk-img></span>
        </div>
        <div class="pf-park-detail name">
          {{ parkDateil.name }}<img src="../assets/official_mark.png" alt="official" class="official-icon"><img src="../assets/logo_parkful.png" alt="info" class="info-icon">
        </div>
        <div class="pf-park-detail prefecture">
          <img src="../assets/logo_pin.png" alt="pin" class="pin-icon"> {{ parkDateil.prefecture.name }} {{ parkDateil.city.name }}
        </div>
        <div class="star-average">
          <starreview :data=getStarAverage(parkDateil)></starreview>
        </div>
        <span v-if="parkDateil.comments">
          <div class="pf-park-detail comment">
            <div class="park-comment">公園紹介</div>
            {{ parkDateil.comments }}                        
          </div>
        </span>
        <div class="pf-park-detail address">
          <div class="park-address">住所</div>
            <span class="address-font"><a :href="addressURL(parkDateil)">{{ parkDateil.address }}</a></span>                   
        </div>
        <div class="pf-park-detail equipment-information">
          <div class="information">設備情報</div>                    
        </div>
        <div class="pf-park-detail playing">
          <div class="park-playing">遊び</div>
          <div class="park-tags" v-for="playing in getParkTags(parkDateil, '遊び')" :key='playing.id'>
            <span class="playing-font">{{ playing }}</span>
          </div>        
        </div>
        <div class="pf-park-detail equipment">
          <div class="park-equipment">設備</div>
          <div class="park-tags" v-for="equipment in getParkTags(parkDateil, '設備')" :key='equipment.id'>
            <span class="equipment-font">{{ equipment }}</span>
          </div>                   
        </div>
      </div>
      <div v-else>
        <h2>読み込み中です。 </h2>
      </div>
    </div>
  </div>
</template>

<script>
import starreview from './StarReview'

export default {
  components: {
    starreview
  },

  methods: {

    async getToken(){
      const formData = new FormData()
      formData.set('tokenkey','975663ca9733ced421b9d249befab1b3')
      await fetch('https://api.parkful.com/api/v4/api_token', {
        method: 'POST',
        body: formData,
        headers: {
          CLIENTID: 'parkful',
          'Accept': 'application/json'
          
        },
      }).then(resp => {
        return resp.json()
      }).then(resp => {
        this.tokendata = resp.token;  // トークンをセット
      });
    },

    getParkDateil(){
      const id = Number(this.$route.params.id);
      fetch(`https://api.parkful.com/api/v4/parks/${id}`, {
        method: 'GET',
        headers: {
          TOKEN: this.tokendata,
          CLIENTID: 'parkful',
          'Authorization': 'Bearer', 
          'Content-Type': 'application/json',
        },
      }).then(resp => {
        return resp.json()
      }).then(resp => {
        this.parkDateil = resp;  // timeline配列にロードしたデータをセット
      });
    },

    // タグごとに配列作成
    getParkTags(ParkDateil, type){
      const tagArray = []
      ParkDateil.tags.forEach(function(val){
        if(val.large_class == type){
            tagArray.push(val.name)
        }
      });
      return tagArray;

    },

    // googleマップURL生成
    addressURL(parkDateil) {
      return 'https://maps.google.co.jp/maps?ll=' + parkDateil.latitude +',' +parkDateil.longitude
    },

    getStarAverage(parkDateil) {
      return parkDateil.review_star_average
    }
  },

  data: function() {
    return { // この画面で管理したいデータ構造を定義
      parkDateil: null,
      tokendata:[],
    }     
  },

  async created () {
    await this.getToken(); // createdのタイミングでデータロード
    this.getParkDateil();
  },

  metaInfo: {
    title: "PARKFUL Web | 公園をもっと楽しく、もっと身近に。 全国11万の公園情報からお気に入りを探そう！",
    meta: [
      { name: 'description', content: "日本全国の公園を横断検索できるサービス。地域、設備情報、公園の特徴などの絞り込みで目的にあった公園を探せます。自治体パートナーやユーザーの皆さまと一緒に、日々公園の情報をアップデート中！専用のモバイルアプリもあります。このようなルールでURL生成することは " },
      
    ],
  },

}
</script>

<style>

@media screen and (min-width: 960px) {
  /* 960px以上に適用されるCSS（PC用） */

  .detail-body {
    margin-left: 11em;
    margin-right: 15em;
  }

  .official-icon {
    margin-left: 10px;
    margin-bottom: 5px;
    width: 20px;
    height: 20px;
  }
  .info-icon {
    margin-top: 6px;
    float: right;
    width: 20px;
    height: 20px;
  }

  .name {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-size: 20px;
    font-weight: bold;
  }

  .prefecture {
    margin-bottom: 0.5em;
    font-size: 13px;
  }

  .comment {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }



  .park-comment {
    margin-bottom: 3px;
    font-weight: bold;
    clear: both;
  }

  .address {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .park-address {
    margin-bottom: 8px;
    font-weight: bold;
    clear: both;
  }

  .address-font {
    padding: 0.5em 1em;
    margin: 2em 0;
    background: #FFF;
    border: solid 1px black;/*線*/
    border-radius: 5px;/*角の丸み*/
  }
  .address-font a {
    color: black;
  }

  .equipment-information {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  .playing {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .park-playing {
    margin-bottom: 3px;
    font-weight: bold;
  }

  .equipment {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .park-equipment {
    margin-bottom: 3px;
    font-weight: bold;
  }
  .playing-font {
    padding: 0.5em 1em;
    margin: 5px 0;
    margin-right: 1em;
    color: #2c2c2f;
    background: rgb(235, 231, 231);/*背景色*/
    float: left;
    font-size: 15px;
  }


  .equipment {
    clear: both;
  }

  .equipment-font {
    padding: 0.5em 1em;
    margin: 5px 0;
    margin-right: 1em;
    color: #2c2c2f;
    background: rgb(235, 231, 231);/*背景色*/
    float: left;
    font-size: 15px;
  }



  
  


}

@media screen and (max-width: 959px) {
	/* 959px以下に適用されるCSS（タブレット用） */

  .detail-body {
    margin-left: 11em;
    margin-right: 15em;
  }
  .official-icon {
    margin-left: 10px;
    margin-bottom: 5px;
    width: 20px;
    height: 20px;
  }


  .name {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-size: 20px;
    font-weight: bold;
  }

  .prefecture {
    margin-bottom: 0.5em;
    font-size: 13px;
  }

  .comment {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }



  .park-comment {
    margin-bottom: 3px;
    font-weight: bold;
    clear: both;
  }

  .address {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }



  .park-address {
    margin-bottom: 8px;
    font-weight: bold;
    clear: both;
  }

  .address-font {
    padding: 0.5em 1em;
    margin: 2em 0;
    background: #FFF;
    border: solid 1px black;/*線*/
    border-radius: 5px;/*角の丸み*/
  }
  .address-font a {
    color: black;
  }

  .equipment-information {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  .playing {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .park-playing {
    margin-bottom: 3px;
    font-weight: bold;
  }

  .equipment {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .park-equipment {
    margin-bottom: 3px;
    font-weight: bold;
  }
  .playing-font {
    padding: 0.5em 1em;
    margin: 5px 0;
    margin-right: 1em;
    color: #2c2c2f;
    background: rgb(235, 231, 231);/*背景色*/
    float: left;
    font-size: 15px;
  }


  .equipment {
    clear: both;
  }

  .equipment-font {
    padding: 0.5em 1em;
    margin: 5px 0;
    margin-right: 1em;
    color: #2c2c2f;
    background: rgb(235, 231, 231);/*背景色*/
    float: left;
    font-size: 15px;
  }

}

@media screen and (max-width: 480px) {
	/* 480px以下に適用されるCSS（スマホ用） */

  .detail-body {
    margin-left: 1em;
    margin-right: 1em;
  }
  .official-icon {
    margin-left: 10px;
    margin-bottom: 5px;
    width: 20px;
    height: 20px;
  }

  
  .name {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-size: 20px;
    font-weight: bold;
  }

  .prefecture {
    margin-bottom: 0.5em;
    font-size: 13px;
  }

  .comment {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }



  .park-comment {
    margin-bottom: 3px;
    font-weight: bold;
    clear: both;
  }

  .address {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }



  .park-address {
    margin-bottom: 8px;
    font-weight: bold;
    clear: both;
  }

  .address-font {
    padding: 0.5em 1em;
    margin: 2em 0;
    background: #FFF;
    border: solid 1px black;/*線*/
    border-radius: 5px;/*角の丸み*/
    word-break: keep-all !important;
  }
  .address-font a {
    color: black;
  }

  .equipment-information {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  .playing {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .park-playing {
    margin-bottom: 3px;
    font-weight: bold;
  }

  .equipment {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .park-equipment {
    margin-bottom: 3px;
    font-weight: bold;
  }
  .playing-font {
    padding: 0.5em 1em;
    margin: 5px 0;
    margin-right: 1em;
    color: #2c2c2f;
    background: rgb(235, 231, 231);/*背景色*/
    float: left;
    font-size: 15px;
  }


  .equipment {
    clear: both;
  }

  .equipment-font {
    padding: 0.5em 1em;
    margin: 5px 0;
    margin-right: 1em;
    color: #2c2c2f;
    background: rgb(235, 231, 231);/*背景色*/
    float: left;
    font-size: 15px;
  }


}


</style>


